/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-05-30 22:54:17
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-12 10:51:32
*/


'use strict';

class Hectare {

    constructor(settings) {
        logger.log('Hectare settings', settings);
        settings        = settings || {};
        this.position   = settings.position || {x: 0, y: 0};
        this.width      = settings.width || 1920;
        this.height     = settings.height || 1080;
        this.name       = settings.name || '';
        this.classes    = settings.classes || 'hectare step';
        this.image      = settings.image || '';
        this.template   = settings.template || null;
        this.callback   = settings.callback || null;
        this.video      = settings.video || null;
        this.videoRoot  = settings.videoRoot || 'videos/';
        this.markersData= settings.markers || null;
        this.markers    = [];
        this.animator   = null;
        this.info       = settings.info || {};

        this.show_profile       = (settings.show_profile !== false);
        this.profile_pic_path   = settings.profile_pic_path || '';

        this.bind();

        return this;
    }

    init() {
        return this;
    }

    createDOM() {
        // Create main DOM wrapper
        this.dom                        = document.createElement('div');
        this.dom.id                     = this.name;
        this.dom.classList.addMany(this.classes);
        this.dom.classList.add('hidden'); // Start hidden
        this.dom.style.width            = this.width + 'px';
        this.dom.style.height           = this.height + 'px';
        this.dom.dataset.x              = this.getPosX();
        this.dom.dataset.y              = this.getPosY();

        // Create inner DOM
        this.dom_inner                  = document.createElement('div');
        this.dom_inner.classList.add('inner');

        // Create image DOM
        this.dom_front                  = document.createElement('div');
        this.dom_front.style.backgroundImage = 'url(' + this.image + ')';
        this.dom_front.classList.add('image');

        // Create profile DOM
        this.dom_back                   = this.createProfile(this.info);

        // Front/back toggles
        this.dom_info_button    = document.createElement('div');
        this.dom_info_button.classList.add('info_button');
        // var info_icon           = document.createElement('i');
        // info_icon.classList.addMany('fa fa-question-circle-o');
        // this.dom_info_button.appendChild(info_icon);

        this.dom_front.appendChild(this.dom_info_button);

        this.dom_play_button    = document.createElement('div');
        this.dom_play_button.classList.add('play_button');
        
        var play_icon           = document.createElement('i');
        play_icon.classList.addMany('fa fa-play-circle-o');
        this.dom_play_button.appendChild(play_icon);

        this.dom_back.appendChild(this.dom_play_button);

        // Load template if any
        if (this.template !== null) {
            logger.log(this.template);
            var request = new Request(this.template);
            fetch(request).then(function(response) {
                return response.blob();
            }).then(function(response) {
                logger.log('template', response);
                var reader = new FileReader();
                reader.addEventListener("loadend", function() {
                    logger.log('template data', reader.result);
                    this.dom_front.innerHTML = reader.result;
                    // Hectarium.initialize();
                }.bind(this));
                reader.readAsBinaryString(response);
            }.bind(this));
        }

        // Put everyhing together
        this.dom_inner.appendChild(this.dom_front);
        this.dom_inner.appendChild(this.dom_back);

        this.dom.appendChild(this.dom_inner);

        this.createVideo();
        this.createMarkers();

        this.bindEvents();

        this.animator = new DOMAnimator(this.dom);

        return this;
    }

    appendTo(el) {
        logger.log(this);
        // el.appendChild(this.dom);

        var whenIsReady = new Promise(function(resolve) {
            var img = new Image();
            img.src = this.image;
            img.addEventListener('load', function() {
                logger.log('image loaded');
                resolve();
            });
        }.bind(this));

        return whenIsReady.then(function() {
            el.appendChild(this.dom);
        }.bind(this));
    }

    createProfile(info) {
        var dom = document.createElement('div');
        dom.classList.add('profile');
        // this.dom_back.innerHTML      = 'profile info here';

        // Insert profile picture if any
        if (typeof info.profile_pic !== 'undefined') {
            this.dom_back_pic       = document.createElement('img');
            this.dom_back_pic.classList.add('profile_pic');
            this.dom_back_pic.src   = this.profile_pic_path + this.info.profile_pic;
            dom.appendChild(this.dom_back_pic);
        }

        var dom_info = document.createElement('div');
        dom_info.classList.add('info');

        this.dom_make_favorite = document.createElement('div');
        this.dom_make_favorite.classList.add('make_favorite');
        this.dom_make_favorite.dataset.farmerKind = this.info.farmer_kind;
        dom_info.appendChild(this.dom_make_favorite);

        if (typeof info.farming_type === 'string') {
            var dom_farming_type = document.createElement('div');
            dom_farming_type.classList.add('farming_type');
            dom_farming_type.innerHTML = info.farming_type;
            dom_info.appendChild(dom_farming_type);
        }

        if (typeof info.name === 'string') {
            var dom_name = document.createElement('div');
            dom_name.classList.add('name');
            dom_name.innerHTML = info.name;
            dom_info.appendChild(dom_name);
        }

        if (typeof info.description === 'string') {
            var dom_description = document.createElement('div');
            dom_description.classList.add('description');
            dom_description.innerHTML = info.description;
            dom_info.appendChild(dom_description);
        }

        var dom_info_farmers = document.createElement('div');
        dom_info_farmers.classList.add('info_farmers');

        if (typeof info.lead_farmers === 'string') {
            var dom_lead_farmers= document.createElement('span');
            dom_lead_farmers.classList.add('lead_farmers');
            dom_lead_farmers.innerHTML = info.lead_farmers;
            dom_info_farmers.appendChild(dom_lead_farmers);
        }

        if (typeof info.municipality === 'string') {
            var dom_municipality = document.createElement('span');
            dom_municipality.classList.add('municipality');
            dom_municipality.innerHTML = info.municipality;
            dom_info_farmers.appendChild(dom_municipality);
        }

        if (typeof info.country === 'string') {
            var dom_country= document.createElement('span');
            dom_country.classList.add('country');
            dom_country.innerHTML = info.country;
            dom_info_farmers.appendChild(dom_country);
        }

        dom_info.appendChild(dom_info_farmers);
        dom.appendChild(dom_info);

        return dom;
    }

    createMarkers() {
        if (!this.markersData) {
            return false;
        }

        for (var i = this.markersData.length - 1; i >= 0; i--) {
            logger.log('creating marker', this.markersData[i]);
            var data = this.markersData[i];
            var marker = new Marker({
                name: data.name,
                level: data.level,
                coords: new Point(data.coords.x, data.coords.y),
                tooltip: data.tooltip
            }).createDOM().appendTo(this.dom_inner);
            
            this.markers.push(marker);
        }
    }

    createVideo() {
        logger.log('createVideo');
        if (!this.video) {
            return false;
        }
        logger.log('creating dom for video', this.video);
        this.videoDOM = document.createElement('video');
        this.videoDOM.id = 'video-' + this.name;
        this.videoDOM.width = this.width;
        this.videoDOM.height = this.height;
        this.videoDOM.preload = true;
        // this.videoDOM.loop = true;
        this.videoDOM.controls = true;

        this.videoSource = document.createElement('source');
        this.videoSource.src = this.videoRoot + this.video;
        this.videoSource.type = 'video/mp4';

        this.videoDOM.appendChild(this.videoSource);
        this.dom_front.appendChild(this.videoDOM);
    }

    toggleProfile() {
        if (this.dom_inner.classList.contains('flipped')) {
            this.hideProfile();
        }
        else {
            this.showProfile();
        }
    }

    showProfile() {
        logger.log('showProfile');
        this.onShowProfile();
        this.stopVideo();
        this.dom_inner.classList.add('flipped');
    }

    onShowProfile() {

    }

    hideProfile() {
        this.dom_inner.classList.remove('flipped');
    }

    profileIsVisible() {
        return this.dom_inner.classList.contains('flipped');
    }

    videoIsVisible() {
        return !this.profileIsVisible();
    }

    playVideo() {
        logger.log('playVideo', this.video);
        if (!this.videoIsVisible()) {
            this.hideProfile();
        }
        if (!this.video) {
            logger.log('no video to play');
            return false;
        }
        logger.log('playing video', this.video);
        this.video_played = true;
        this.videoDOM.play();
    }

    stopVideo() {
        logger.log('stopVideo');
        if (!this.video) {
            logger.log('no video to play');
            return false;
        }
        logger.log('playing video', this.video);
        this.videoDOM.pause();
    }

    onVideoPause() {
        return this;
    }

    onEnter() {
        logger.log("hectare ", this.name, " displayed");
        logger.write("hectare " + this.name + " displayed");
        logger.newAction("hectare " + this.name + " displayed");
        this.dom.classList.addMany('active entered_once');
        this.makeCallback();
        // $(this.videoDOM).fadeIn(1500);
        // this.playVideo();
        this.showMarkers();
        if (!this.profileIsVisible() && this.show_profile) {
            this.showProfile();
        }
        window.hectariumController.setActiveHectare(this);
    }

    onLeave() {
        logger.log("hectare ", this.name, " left");
        this.dom.classList.remove('active');
        // $(this.videoDOM).fadeOut(1500);
        this.stopVideo();
        this.hideProfile();
    }

    onRequested() {
        logger.log("hectare", this.name, "requested");
        // return true;
        if (this.isActive()) {
            logger.log('already active');
            return false;
        }
        if (this.show_profile) {
            this.showProfile();
        }
        // $(this.videoDOM).fadeIn(1500);
        // this.playVideo();
        this.makeCallback();
    }

    onLoaded() {
    }

    makeCallback() {
        if (this.callback !== null) {
            logger.log('callback', this.callback);
            executeFunctionByName(this.callback, window);
            this.callback = null;
        }
    }

    bindEvents() {
        this.dom.addEventListener('impress:stepenter', this.onEnter);

        this.dom.addEventListener('impress:stepleave', this.onLeave);

        this.dom.addEventListener('hectare:requested', this.onRequested);

        this.dom.addEventListener('click', this.onRequestedEventTrigger);

        this.dom_play_button.addEventListener('click', this.playVideo);

        this.dom_info_button.addEventListener('click', this.showProfile);

        if (typeof this.videoDOM !== 'undefined')
            this.videoDOM.addEventListener('pause', this.onVideoPause);

        if (typeof this.dom_make_favorite !== 'undefined') {
            this.dom_make_favorite.addEventListener('click', this.onMakeFavorite);
        }
    }

    onMakeFavorite(e) {
        var target = e.currentTarget;
        if (typeof target === 'undefined') {
            return false;
        }
        var current = document.querySelector('.make_favorite.selected');
        if (current) {
            current.classList.remove('selected');
        }
        target.classList.add('selected');
        window.farmer.setKind(target.dataset.farmerKind);
    }

    onRequestedEventTrigger(e) {
        console.log(e);
        if (window.hectariumController.getActiveHectare == this) {
            e.stopPropagation();
        }
        window.eventDispatcher.triggerEvent(this.dom, 'hectare:requested');
    }

    getPosX() {
        return this.position.x * this.width;
    }

    getPosY() {
        return this.position.y * this.height;
    }

    isActive() {
        return this.dom.classList.contains('active');
    }

    showMarkers() {
        logger.log('Hectare - showMarkers()', this.markers);
        for (var i = this.markers.length - 1; i >= 0; i--) {
            this.markers[i].show();
        }
    }

    bind() {
        this.onEnter                    = this.onEnter.bind(this);
        this.onLeave                    = this.onLeave.bind(this);
        this.onRequested                = this.onRequested.bind(this);
        this.onLoaded                   = this.onLoaded.bind(this);
        this.onRequestedEventTrigger    = this.onRequestedEventTrigger.bind(this);
        this.toggleProfile              = this.toggleProfile.bind(this);
        this.playVideo                  = this.playVideo.bind(this);
        this.showProfile                = this.showProfile.bind(this);
        this.onVideoPause               = this.onVideoPause.bind(this);
    }
}