/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-05-30 23:14:43
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-11 16:48:14
*/

'use strict';

class HectariumController {

    constructor() {
        this.jsonFile = 'data.json';
        this.json = null;
        this.impress = null;
        this.wrapper = null;
        this.logo = null;
        this.scale_factor = 1;
        this.loading_screen = null;
        this.hectares = [];

        this.settings = {
            hectares: {
                width:              1920,
                height:             1080,
                image_path:         'dist/images/hectares/',
                profile_pic_path:   'dist/images/profiles/'
            }
        };

        this.init();
    }

    init() {
        this.bind();

        this.wrapper = document.querySelector('#impress');
        this.logo = document.querySelector('#logo');
        this.loading_screen = document.querySelector('#loading');
        this.username_input = document.querySelector('#username_input');
        this.scale_factor = 1;
        
        this.counterDOM = document.querySelector('#counter');
        this.counter = new Counter(this.counterDOM);
        this.getData(function() {
            this.createElements()
                .then(this.impressInit)
                .then(this.onLoaded);
        }.bind(this));

        this.bindEvents();
    }

    bind() {
        this.createElements = this.createElements.bind(this);
        this.impressInit = this.impressInit.bind(this);
        this.goHome = this.goHome.bind(this);
        this.onLoaded = this.onLoaded.bind(this);
        this.hideLoadingScreen = this.hideLoadingScreen.bind(this);
    }

    bindEvents() {
        this.documentGestures = new Hammer(document, {});
        this.documentGestures.get('pinch').set({ enable: true });
        this.documentGestures.on('pinchin', this.goHome);

        this.logoGestures = new Hammer(this.logo, {});
        this.logoGestures.on('tap', this.goHome);
        this.logo.addEventListener('click', this.goHome);
    }

    onLoaded() {
        this.hideLoadingScreen();
        this.goTo('my_hectare');

        for (var i = this.hectares.length - 1; i >= 0; i--) {
            this.hectares[i].animator.delay(i * 0.2).animateIn();
            this.hectares[i].onLoaded();
        }
    }

    createElements() {
        if (!this.json || this.json.hectares.length === 0) {
            return false;
        }

        var promises = [];
        var hectares = this.json.hectares;
        var className = '';

        for (var i = hectares.length - 1; i >= 0; i--) {
            var data = hectares[i];
            logger.log(hectares[i]);

            if (typeof data.class === 'string') {
                className = data.class;
            }
            else {
                className = 'Hectare';
            }
            console.log(className);
            var hectare = new window[className]({
                position: {
                    x: data.position.x,
                    y: data.position.y
                },
                width: data.width,
                height: data.height,
                name: data.name,
                image: this.settings.hectares.image_path + data.image,
                show_profile: data.show_profile,
                profile_pic_path: this.settings.hectares.profile_pic_path,
                info: data.info,
                template: data.template,
                callback: data.callback,
                video: data.video,
                markers: data.markers
            }).createDOM();
            this.hectares.push(hectare);
            promises.push(hectare.appendTo(this.wrapper));
        }

        return Promise.all(promises);
    }

    goHome() {
        console.log('goHome');
        if (typeof this.impress === 'undefined') {
            return false;
        }
        this.goTo(0);
        this.setActiveHectare(null);
        logger.write('overview displayed');
    }

    goTo(id) {
        this.impress.goto(id);
    }

    setActiveHectare(hectare) {
        logger.log('setActiveHectare');
        this.active_hectare = hectare;
    }

    getActiveHectare() {
        return this.active_hectare;
    }

    impressInit() {
        return new Promise(function(resolve) {
            this.impress = impress();
            this.impress.init({
                width: 1920,
                height: 1350
            });
            resolve();
        }.bind(this));
    }

    hideLoadingScreen() {
        $(this.loading_screen).fadeOut();
    }

    getData(callback) {
        jQuery.getJSON(this.jsonFile, function(json) {
            logger.log(json);
            this.json = json;
            if (typeof callback === 'function') {
                callback();
            }
        }.bind(this));
    }
}