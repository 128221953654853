/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-07-07 14:38:55
* @Last Modified by:   Paul
* @Last Modified time: 2016-07-11 14:28:03
*/

'use strict';

class DOMAnimator {

    constructor(dom, settings) {
        this.dom = dom;
        this.settings = settings || {
            base_class: 'animated',
            hide_class: 'hidden',
            animation_end: 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
            in: {
                animation: 'fadeIn'
            },
            out: {
                animation: 'fadeOut'
            }
        };
        // this.settings.base_class = settings.base_class || 'animated';
        // this.settings.hide_class = settings.hide_class || 'hidden';
        // this.settings.animation_end = settings.animation_end || 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        // this.settings.in.animation = settings.in.animation || 'fadeIn';
        // this.settings.out.animation = settings.out.animation || 'fadeOut';
        this.bind();

        return this;
    }

    bind() {
        this.animateIn  = this.animateIn.bind(this);
        this.animateOut = this.animateOut.bind(this);
    }

    // Delays animation by [duration] seconds
    delay(duration) {
        if (typeof duration === 'undefined' || duration === 0) {
            return this;
        }
        this.dom.style.animationDelay = duration + 's';

        return this;
    }

    // Triggers in animation
    animateIn() {
        logger.log('animateIn', this.settings);
        this.dom.classList.remove(this.settings.hide_class);
        this.dom.classList.add(this.settings.base_class);
        this.dom.classList.add(this.settings.in.animation);

        return this;
    }

    // Trigger out animation
    animateOut() {
        this.dom.classList.add(this.settings.base_class);
        this.dom.classList.add(this.settings.out.animation);

        return this;
    }
}