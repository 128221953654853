/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-08-05 11:59:22
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-12 11:38:06
*/


'use strict';

class MyHectare extends Hectare {

    constructor(settings) {
        super(settings);
        this.resources = [
            new Resource({
                name: 'animals',
                portion: 125,
                surface_per_portion: 1500
            }),
            new Resource({
                name: 'cereals',
                portion: 125,
                surface_per_portion: 75
            }),
            new Resource({
                name: 'oils',
                portion: 125,
                surface_per_portion: 75
            }),
            new Resource({
                name: 'greens',
                portion: 125,
                surface_per_portion: 2
            }),
            new Resource({
                name: 'forest',
                portion: 1,
                surface_per_portion: 5
            })
        ];
        this.video_played = false;
    }

    init() {
        this.videoDOM = this.dom.querySelector('video');
        this.dom_choices = this.dom.querySelector('#start_choices');
        this.dom_choice_how_to = this.dom_choices.querySelector('#how_to');
        this.dom_choice_what_to = this.dom_choices.querySelector('#what_to');

        this.$gridstack = $('.grid-stack');
        this.gridstack = document.querySelector('.grid-stack');
        this.toolbar = document.querySelector('.my_hectare_toolbar');
        this.add_zone_buttons = this.toolbar.querySelectorAll('.zone_icon');
        this.button_show_profile = this.dom.querySelector('.info_button');

        this.area_total = 1920*1080;
        this.surface_m2 = 10000;
        this.progress_bars = [];

        this.gs_options = {
            float: true,
            verticalMargin: 0,
            height: 18,
            draggable: {
                handle: '.grid-stack-item-content',
                scroll: false,
                appendTo: this.dom.id,
                containment: this.dom.id,
                cursorAt: {
                    left: 0,
                    top: 0
                }
            },
            resizable: {
                handles: 'nw, n, ne, e, se, s, sw, w'
            }
        };

        this.$gridstack.gridstack(this.gs_options);
        this.grid = this.$gridstack.data('gridstack');

        this.initAddZoneButtons().myBind().bindMyEvents().updateResourcesData();
    }

    initAddZoneButtons() {
        Array.from(this.add_zone_buttons).forEach(function(current, index) {
            var type = current.dataset.type;
            this.progress_bars[type] = new ProgressBar.Circle(current, {
                strokeWidth: 6,
                easing: 'easeInOut',
                duration: 1400,
                color: '#FFEA82',
                trailColor: '#eee',
                trailWidth: 1,
                svgStyle: null,
                text: {
                    autoStyleContainer: false,
                    style: null
                },
                from: { width: 8 },
                to: { width: 8 },
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);
                    circle.setText(value);
                }
            });
        }.bind(this));

        return this;
    }

    addZone(type) {
        var node = {
            x: 0,
            y: 0,
            width: 3,
            height: 3
        };

        var willFit = this.grid.willItFit(node.x, node.y, node.width, node.height, true);
        
        if (!willFit) {
            console.log('cant add more zones');
            return false;
        }

        var $zone = $('<div class="' + type + ' zone" />');
        var $inner = $('<div class="grid-stack-item-content zone-content" />');
        var $btn_delete = $('<button class="remove_zone" />');

        $btn_delete.click(this.onDeleteZone);

        $inner.append($btn_delete);
        $zone.append($inner);

        this.grid.addWidget(
            $zone,
            node.x,
            node.y,
            node.width,
            node.height,
            true
        );

        return this;
    }

    onDeleteZone(e) {
        console.log(e);
        var zone = e.currentTarget.parentNode.parentNode;
        if (typeof zone !== 'undefined') {
            zone.remove();
        }

        return this;
    }

    onAddZone(e) {
        var el = e.currentTarget;
        var type = el.dataset.type || '';
        this.addZone(type);
        this.updateResourcesData();
    }

    onRequested() {
        logger.log("hectare", this.name, "requested");
        // return true;
        if (this.isActive()) {
            logger.log('already active');
            return false;
        }
        if (this.show_profile) {
            this.showProfile();
        }

        this.makeCallback();
    }

    makeCallback() {
        if (this.callback !== null) {
            logger.log('callback', this.callback);
            executeFunctionByName(this.callback, this);
            this.callback = null;
        }
    }

    onZoneResize(e, ui) {
        var el = e.target;
        this.updateResourcesData();
    }

    onShowProfile() {
        this.updateResourcesData(true);
        window.farmer.updateAvatar();
    }

    updateResourcesData(all = false) {
        Array.from(this.add_zone_buttons).forEach(function(current, index) {
            var type = current.dataset.type;
            var percent = this.getAreaPercentForType(type);
            this.progress_bars[type].animate(percent/100);

            var index = this.getResourceIndexWithName(type);
            console.log(index);
            if (index !== -1) {
                this.resources[index].setSurface(this.getAreaM2FromPercent(percent));
                console.log(this.resources[index]);
                console.log(this.resources[index].getPortionTotal());
                if (all) {
                    if (typeof this.dom_resouces[type] !== 'undefined') {
                        this.dom_resouces[type].innerHTML = Math.round(this.resources[index].getPortionTotal()) + 'g ' + type;
                    }
                }
            }
        }.bind(this));

        return this;
    }

    bindMyEvents() {
        this.$gridstack.on('resizestop', this.onZoneResize);

        this.button_show_profile.addEventListener('click', this.showProfile);

        Array.from(this.add_zone_buttons).forEach(function(current, index) {
            if (current.classList.contains('disabled'))
                return;
            current.addEventListener('click', this.onAddZone)
        }.bind(this));

        this.dom_gender_female.addEventListener('click', this.onGenderChoice);
        this.dom_gender_male.addEventListener('click', this.onGenderChoice);

        this.videoDOM.addEventListener('pause', this.onVideoPause);
        this.videoDOM.addEventListener('end', this.onVideoEnd);

        this.dom_choice_what_to.addEventListener('click', this.choseWhatTo);
        this.dom_choice_how_to.addEventListener('click', this.choseHowTo);

        this.dom_submit.addEventListener('click', this.onCommentSubmit);

        return this;
    }

    choseHowTo(e) {
        e.stopPropagation();
        this.onChoiceMade();
        window.farmer.setFirstChoice('how to farm');
        window.hectariumController.goHome();
    }

    choseWhatTo(e) {
        e.stopPropagation();
        this.onChoiceMade();
        window.farmer.setFirstChoice('what to farm');
    }

    onChoiceMade() {
        this.toolbar.classList.add('choice_made');
        this.hideVideo().hideChoices();
    }

    onGenderChoice(e) {
        console.log(e);
        var target = e.currentTarget;
        if (typeof target === 'undefined') {
            return false;
        }
        window.farmer.setGender(target.dataset.gender);
    }

    onCommentSubmit(e) {
        e.stopPropagation();

        var email = this.dom_email_field.value || '';
        var comment = this.dom_comment_field.value || '';

        window.farmer.setEmail(email);
        window.farmer.setComment(comment);
        window.logger.write(window.farmer.getSummary());
    }

    myBind() {
        this.onZoneResize = this.onZoneResize.bind(this);
        this.onAddZone = this.onAddZone.bind(this);
        this.choseHowTo = this.choseHowTo.bind(this);
        this.choseWhatTo = this.choseWhatTo.bind(this);
        this.onCommentSubmit = this.onCommentSubmit.bind(this);
        this.onDeleteZone = this.onDeleteZone.bind(this);

        return this;
    }

    getAreaM2FromPercent(percent) {
        if (percent === 0) {
            return 0;
        }
        return (this.surface_m2 / 100) * percent;
    }

    getAreaPercentForType(type) {
        if (typeof type !== 'string') {
            return false;
        }
        var area = 0;
        if (type === 'forest') {
            area = this.getUnusedAreaTotal();
        }
        else {
            area = this.getAreaTotalForType(type);
        }
        return (area / this.area_total) * 100;
    }

    getUnusedAreaTotal() {
        var zones = this.gridstack.querySelectorAll('.zone');
        var used_area = 0;
        Array.from(zones).forEach(function(current, index) {
            used_area += this.getAreaForZone(current);
        }.bind(this));
        return this.area_total - used_area;
    }

    getAreaTotalForType(type) {
        if (typeof type !== 'string') {
            return false;
        }
        var zones = this.gridstack.querySelectorAll('.zone.' + type);
        var area_total = 0;
        Array.from(zones).forEach(function(current, index) {
            area_total += this.getAreaForZone(current);
        }.bind(this));

        return area_total;
    }

    getAreaForZone(zone) {
        if (typeof zone === 'undefined') {
            return false;
        }
        return zone.offsetWidth * zone.offsetHeight;
    }

    createProfile() {
        var dom = document.createElement('div');
        dom.classList.add('profile');
        // this.dom_back.innerHTML      = 'profile info here';


        var dom_farmer = document.createElement('div');
        dom_farmer.classList.add('farmer');

        // Avatar
        var dom_avatar = document.createElement('div');
        dom_avatar.classList.add('avatar');
        dom_farmer.appendChild(dom_avatar);

        // Gender select
        var dom_gender_select = document.createElement('div');
        dom_gender_select.classList.add('gender_select');

        this.dom_gender_female = document.createElement('div');
        this.dom_gender_female.classList.add('gender_choice');
        this.dom_gender_female.classList.add('female');
        this.dom_gender_female.dataset.gender = 'female';

        this.dom_gender_male = document.createElement('div');
        this.dom_gender_male.classList.add('gender_choice');
        this.dom_gender_male.classList.add('male');
        this.dom_gender_male.dataset.gender = 'male';

        dom_gender_select.appendChild(this.dom_gender_female);
        dom_gender_select.appendChild(this.dom_gender_male);

        dom_farmer.appendChild(dom_gender_select);

        // Comments
        var dom_comments = document.createElement('div');
        dom_comments.classList.add('comments');

        this.dom_farmer_kind = document.createElement('div');
        this.dom_farmer_kind.classList.add('farmer_kind');
        dom_comments.appendChild(this.dom_farmer_kind);

        this.dom_comment_field = document.createElement('textarea');
        this.dom_comment_field.classList.add('comment');
        this.dom_comment_field.placeholder = 'Why? add a short comment (optional)';
        dom_comments.appendChild(this.dom_comment_field);

        this.dom_email_field = document.createElement('input');
        this.dom_email_field.type = 'text';
        this.dom_email_field.classList.add('farmer_email');
        this.dom_email_field.placeholder = 'Your email';
        dom_comments.appendChild(this.dom_email_field);

        this.dom_submit = document.createElement('button');
        this.dom_submit.type = 'button';
        this.dom_submit.innerHTML = 'Share it';
        this.dom_submit.classList.add('submit');
        dom_comments.appendChild(this.dom_submit);

        dom_farmer.appendChild(dom_comments);

        // Dishes & napkin
        var dom_produce = document.createElement('div');
        dom_produce.classList.add('produce');

        var dom_produce_data = document.createElement('div');
        dom_produce_data.classList.add('produce_data');

        this.dom_resouces = [];
        this.resources.forEach(function(current, index) {
            console.log(current);
            this.dom_resouces[current.name] = document.createElement('div');
            this.dom_resouces[current.name].classList.add(current.name);
            dom_produce_data.appendChild(this.dom_resouces[current.name]);
        }.bind(this));
        dom_produce.appendChild(dom_produce_data);

        dom.appendChild(dom_farmer);
        dom.appendChild(dom_produce);
        

        return dom;
    }

    getResourceIndexWithName(name) {
        for (var i = this.resources.length - 1; i >= 0; i--) {
            if (this.resources[i].name === name) {
                return i;
            }
        }
        return -1;
    }

    onEnter() {
        // logger.write("hectare " + this.name + " displayed");
        this.dom.classList.addMany('active entered_once');
        this.makeCallback();
        this.showMarkers();
        if (!this.profileIsVisible() && this.show_profile) {
            this.showProfile();
        }
        if (!this.video_played) {
            this.playVideo();
        }
        window.hectariumController.setActiveHectare(this);
        this.toolbar.classList.add('visible');
    }

    onLeave() {
        logger.log("hectare ", this.name, " left");
        this.dom.classList.remove('active');
        this.toolbar.classList.remove('visible');
        this.hideProfile();
        this.stopVideo();
        this.hideVideo();
    }

    playVideo() {
        if (!this.videoIsVisible()) {
            this.hideProfile();
        }
        if (this.videoDOM.classList.contains('dont_play')) {
            return false;
        }
        this.dom.classList.add('video_played');
        this.video_played = true;
        this.videoDOM.play();
    }

    stopVideo() {
        this.videoDOM.pause();

        return this;
    }

    hideVideo() {
        this.videoDOM.classList.add('display_none');

        return this;
    }

    onVideoPause() {
        console.log('onVideoPause');
        this.showChoices();
        this.videoDOM.classList.add('dont_play');
        return this;
    }

    onVideoEnd() {
        console.log('onVideoEnd');
        this.showChoices();
        this.videoDOM.classList.add('dont_play');
        return this;
    }

    showChoices() {
        if (this.dom_choices.classList.contains('showed_once')) {
            return false;
        }
        this.dom_choices.classList.remove('display_none');
        this.dom_choices.classList.remove('hidden');

        return this;
    }

    hideChoices() {
        this.dom_choices.classList.add('display_none');
        this.dom_choices.classList.add('hidden');
        this.dom_choices.classList.add('showed_once');

        return this;
    }
}