/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-08-10 16:19:44
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-12 10:22:07
*/

'use strict';

class Farmer {

    constructor(data) {
        data = data || {};

        // Data
        this.email          = data.email || '';
        this.gender         = data.gender || 'female';
        this.kind           = data.kind || '';
        this.first_choice   = data.first_choice || '';
        this.comment        = data.comment || '';
    }

    setEmail(email) {
        this.email = email;
    }

    setGender(gender) {
        this.gender = gender;
        this.updateAvatar();
    }

    setKind(kind) {
        this.kind = kind;
        this.updateFarmerKind();
    }

    setFirstChoice(choice) {
        this.first_choice = choice;
    }

    setComment(comment) {
        this.comment = comment;
    }

    updateAvatar() {
        var dom = document.querySelector('#my_hectare .farmer .avatar');

        if (!dom) {
            return false;
        }
        if (this.gender !== '') {
            dom.dataset.gender = this.gender;
        }
    }

    updateFarmerKind() {
        var dom = document.querySelector('#my_hectare .farmer .farmer_kind');

        if (!dom) {
            return false;
        }
        if (this.kind !== '') {
            dom.innerHTML = 'I would like to be a ' + this.kind;
        }
    }

    getSummary() {
        return JSON.stringify({
            'email':          this.email,
            'comment':        this.comment,
            'first_choice':   this.first_choice,
            'kind':           this.farmer_kind,
            'gender':         this.gender
        });
    }

}