/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-07-21 14:58:16
* @Last Modified by:   Paul
* @Last Modified time: 2016-07-21 15:14:56
*/

'use strict';

class Action {
    
    constructor(data = {}) {
        if (typeof window.current_action !== 'undefined') {
            window.current_action.finish();
        }
        this.start = new Date();
        this.end = null;
        this.elapsed_time = 0;
        this.message = data.message || '';
        window.current_action = this;
    }

    finish() {
        this.end = new Date();
        this.elapsed_time = this.end.getTime() - this.start.getTime();
    }    
}