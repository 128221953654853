/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-05-25 16:35:57
* @Last Modified by:   Paul
* @Last Modified time: 2016-06-05 11:30:08
*/

'use strict';

var Counter = function(counterDOM) {
    this.DAY_IN_SECONDS = 60 * 60 * 24;
    this.apiRoot = 'http://api.population.io:80/1.0/';
    this.endpoint = 'population/World/today-and-tomorrow/';
    this.stats_today;
    this.stats_tomorrow;
    this.stats_now = {
        population: 0,
        date: new Date(),
        land: 0
    };
    this.stats_interval;
    this.land_per_person = 0;

    // DOM
    this.el_land_per_person = counterDOM || null;

    this.stats_interval = setInterval(function() {
        this.stats_now = this.calculateCurrentPopulation(this.stats_today, this.stats_tomorrow);
        this.stats_now.land = this.counter();
        this.el_land_per_person.innerHTML =parseFloat(this.stats_now.land / this.stats_now.population).toFixed(6) + ' ha';
    }.bind(this), 1000);


    $.ajax({
        url: this.apiRoot + this.endpoint,

        success: function(data) {
            this.stats_today = data.total_population[0];
            this.stats_tomorrow = data.total_population[1];
            this.stats_now = this.calculateCurrentPopulation(this.stats_today, this.stats_tomorrow);
        }.bind(this)
    });
};

Counter.prototype.update = function() {
    this.stats_now = this.calculateCurrentPopulation(this.stats_today, this.stats_tomorrow);
    this.stats_now.land = this.counter();
    this.land_per_person = parseFloat(this.stats_now.land / this.stats_now.population).toFixed(6);
    return this.land_per_person;
};

Counter.prototype.calculateCurrentPopulation = function(today, tomorrow) {
    if (typeof today === 'undefined' || typeof tomorrow === 'undefined')
        return null;
    var diff = tomorrow.population - today.population;
    var speed = diff / this.DAY_IN_SECONDS;

    var date_today = new Date(today.date);
    var now = new Date();
    var secondsToday = now.getTime() - date_today.getTime();

    return {
        population: Math.round(today.population + (secondsToday * speed)),
        date: now
    };
};


Counter.prototype.counter = function() {

    this.startpop    =   5946422755;
    //this.startland   =   8596978646;
    this.startland = 4922274500; // Valeur trouvée par Diego
    this.poprate     =   2.4452;
    this.landrate    =   0.13035;

    this.today = new Date()
    this.startdatepop = new Date("February 1, 1999"); // Valeur trouvée par Diego
    //this.startdateland = new Date("January 1, 1999")    

    this.startdateland = new Date("January 1, 2013");
    this.offset = this.today.getTimezoneOffset() * 60 * 1000
    this.diffland = (( this.today.getTime() + this.offset ) - this.startdateland.getTime() ) / 1000;

    var newpop = Math.ceil(this.startpop + (this.diffpop * this.poprate));
    newpop = "" + newpop;

    var newland = Math.ceil(this.startland - (this.diffland * this.landrate));

    return newland;
}