/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-07-07 10:56:27
* @Last Modified by:   Paul
* @Last Modified time: 2016-07-21 15:10:23
*/

'use strict';

class Logger {

    constructor() {
        this.actions = [];
        this.last_action_time = null;
        this.settings = settings.logger || {
            url: '',
            method: '',
            async: true
        };
    }

    newAction(message) {
        console.log('newAction');
        var action = new Action({
            message: message
        });
        this.actions.push(action);
        console.log(action, this.actions);
        return action;
    }

    /**
     * log()
     * Prints arguments to the console using console.log()
     * if debug mode enabled
     */
    log() {
        if (!settings.app.debug) {
            return false;
        }
        console.log.apply(console, arguments);
    }

    /**
     * warn()
     * Prints arguments to the console using console.warn()
     * if debug mode enabled
     */
    warn() {
        if (!settings.app.debug) {
            return false;
        }
        console.warn.apply(console, arguments);
    }

    /**
     * write(data)
     * data string
     * Writes data to local logfile
     */
    write(data) {
        var now = Date.now();
        var formData = new FormData();

        if (this.last_action_time !== null) {
            formData.append('elapsed_time', now - this.last_action_time);
            this.log('elapsed_time', now - this.last_action_time);
        }
        formData.append('message', data);
        formData.append('timestamp', now);
        this.warn(this.last_action_time);
        this.warn(formData);
        this.last_action_time = now;
        this.makeRequest(formData);
    }

    makeRequest(data) {
        var request = new Request(this.settings.url, {
            method: this.settings.method,
            body: data
        });

        fetch(request).then(function(response) {
            this.warn(response);
            return response.blob();
        }.bind(this));
    }

}