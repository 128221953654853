/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-06-05 11:56:49
* @Last Modified by:   Paul
* @Last Modified time: 2016-06-05 12:00:54
*/

'use strict';

var EventDispatcher = function() {
    return this;
};

EventDispatcher.prototype.triggerEvent = function(el, eventName, detail) {
    var event = document.createEvent( "CustomEvent" );
    event.initCustomEvent( eventName, true, true, detail );
    el.dispatchEvent( event );
};