/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-06-05 14:19:01
* @Last Modified by:   Paul
* @Last Modified time: 2016-07-11 15:05:12
*/

'use strict';

class Marker {
    constructor(settings) {
        logger.log('Marker constructor', settings);
        settings            = settings || {};
        this.name           = settings.name || 'plants';
        this.level          = settings.level || 1;
        this.coords         = settings.coords || new Point(0, 0);
        this.icons_class    = settings.icons_class || 'markers';
        this.class          = settings.class || this.icons_class + '-' + this.name + '-' + this.level;
        this.tooltip        = settings.tooltip || null;

        this.tooltip_class  = 'hint--';
    }

    createDOM() {
        logger.log(this.coords);

        // Main DOM
        this.dom = document.createElement('div');
        this.dom.classList.add(this.icons_class);
        this.dom.classList.add(this.class);
        this.dom.style.transform = `translate(${this.coords.x}px, ${this.coords.y}px)`;

        // Tooltip data
        if (this.tooltip) {
            logger.log('tooltip', typeof this.tooltip.text);
            if (typeof this.tooltip.text === 'string') {
                this.dom.setAttribute('aria-label', this.tooltip.text);

                var pos = this.tooltip.position || 'right';
                this.dom.classList.add(this.tooltip_class + pos);
            }
        }

        return this;
    }

    appendTo(el) {
        logger.log(this);
        el.appendChild(this.dom);

        return this;
    }

    show() {
        return this;
    }
}