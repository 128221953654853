/**
 * Created by kenny on 2016-06-08.
 */
var Hectarium = (function () {

  var controlSliders;
  var controlArray;
  var areaTypes;
  var hectariumArray;
  var totalArea = 10000;
  var totalAreaTypes = 4;
  var grainsForAnimalRatio = 0.13;
  var hectariumImagesPerSide = 10;
  var aSlid,cSlid,oSlid,gSlid,fSlid;

  function initialize() {
    setAreas();
    initializeSliders();
    createHectarium();

  }
  
  function setAreas() {
    controlArray = new Array();
    controlArray["control_animals"] = controlAnimals;
    controlArray["control_cereals"] = controlCereals;
    controlArray["control_greens"] = controlGreens;
    controlArray["control_oils"] = controlOils;
    controlArray["control_forest"] = controlForest;
    areaTypes = new Array();
    areaTypes[0] = new Object();
    areaTypes[1] = new Object();
    areaTypes[2] = new Object();
    areaTypes[3] = new Object();
    areaTypes[4] = new Object();
    areaTypes[0].type = "animals";
    areaTypes[0].amount = 0;
    areaTypes[1].type = "cereals";
    areaTypes[1].amount = 0;
    areaTypes[2].type = "greens";
    areaTypes[2].amount = 0;
    areaTypes[3].type = "oils";
    areaTypes[3].amount = 0;
    areaTypes[4].type = "forest";
    areaTypes[4].amount = 0;
  }

  function controlAnimals(value) {
    var animalArea = parseFloat(value);
    var cerealArea = parseFloat(cSlid.get());
    var greensArea = parseFloat(gSlid.get());
    var oilsArea = parseFloat(oSlid.get());
    var forestArea = 0;
    var maxAnimalArea = totalArea - (totalArea*grainsForAnimalRatio);
    if (animalArea > maxAnimalArea) animalArea = maxAnimalArea;
    var minCerealArea = animalArea * 0.13;
    var areaDeficit = (animalArea+cerealArea+greensArea+oilsArea) - totalArea;
    if (areaDeficit > 0) {
      var distributedDeficit = areaDeficit * (totalAreaTypes - 1);
      cerealArea -=  distributedDeficit;
      greensArea -=  distributedDeficit;
      oilsArea -=  distributedDeficit;
      if (cerealArea < minCerealArea) {
        var cerealDeficit = minCerealArea - cerealArea;
        cerealArea = minCerealArea;
        greensArea -= (cerealDeficit / 2);
        oilsArea -= (cerealDeficit / 2);
      }
    } else {
      forestArea = (0 - areaDeficit);
      if (cerealArea < minCerealArea) cerealArea = minCerealArea;
    }
    if (value > animalArea) aSlid.set(animalArea);
    cSlid.set(cerealArea);
    gSlid.set(greensArea);
    oSlid.set(oilsArea);
    fSlid.set(forestArea);
    displayAreas();
  }

  function controlCereals(value) {
    var cerealArea = parseFloat(value);
    var animalArea = parseFloat(aSlid.get());
    var greensArea = parseFloat(gSlid.get());
    var oilsArea = parseFloat(oSlid.get());
    var forestArea = 0;
    var areaDeficit = (animalArea+cerealArea+greensArea+oilsArea) - totalArea;
    var minCerealArea = (animalArea * 0.13);
    if (cerealArea < minCerealArea) cerealArea = minCerealArea;
    if (areaDeficit > 0) {
      var distributedDeficit = areaDeficit / (totalAreaTypes - 1);
      animalArea -=  distributedDeficit;
      greensArea -=  distributedDeficit;
      oilsArea -=  distributedDeficit;
    } else {
      forestArea = (0 - areaDeficit);
    }
    if (value < cerealArea) cSlid.set(cerealArea);
    aSlid.set(animalArea);
    gSlid.set(greensArea);
    oSlid.set(oilsArea);
    fSlid.set(forestArea);
    displayAreas();
  }

  function controlGreens(value) {
    var greensArea = parseFloat(value);
    var animalArea = parseFloat(aSlid.get());
    var cerealArea = parseFloat(cSlid.get());
    var oilsArea = parseFloat(oSlid.get());
    var forestArea = 0;
    var minCerealArea = (animalArea * 0.13);
    var areaDeficit = (animalArea+cerealArea+greensArea+oilsArea) - totalArea;
    if (areaDeficit > 0) {
      var distributedDeficit = areaDeficit / (totalAreaTypes - 1);
      animalArea -=  distributedDeficit;
      cerealArea -=  distributedDeficit;
      oilsArea -=  distributedDeficit;
      if (cerealArea < minCerealArea) {
        var cerealDeficit = minCerealArea - cerealArea;
        cerealArea = minCerealArea;
        animalArea -= (cerealDeficit / 2);
        oilsArea -= (cerealDeficit / 2);
      }
    } else {
      forestArea = (0 - areaDeficit);
    }
    aSlid.set(animalArea);
    cSlid.set(cerealArea);
    oSlid.set(oilsArea);
    fSlid.set(forestArea);
    displayAreas();
  }

  function controlOils(value) {
    var oilsArea = parseFloat(value);
    var animalArea = parseFloat(aSlid.get());
    var cerealArea = parseFloat(cSlid.get());
    var greensArea = parseFloat(gSlid.get());
    var forestArea = 0;
    var minCerealArea = (animalArea * 0.13);
    var areaDeficit = (animalArea+cerealArea+greensArea+oilsArea) - totalArea;
    if (areaDeficit > 0) {
      var distributedDeficit = areaDeficit / (totalAreaTypes - 1);
      animalArea -=  distributedDeficit;
      cerealArea -=  distributedDeficit;
      greensArea -=  distributedDeficit;
      if (cerealArea < minCerealArea) {
        var cerealDeficit = minCerealArea - cerealArea;
        cerealArea = minCerealArea;
        animalArea -= (cerealDeficit / 2);
        greensArea -= (cerealDeficit / 2);
      }
    } else {
      forestArea = (0 - areaDeficit);
    }
    aSlid.set(animalArea);
    cSlid.set(cerealArea);
    gSlid.set(greensArea);
    fSlid.set(forestArea);
    displayAreas();
  }

  function controlForest(value) {
    var forestArea = parseFloat(value);
    var greensArea = parseFloat(gSlid.get());
    var animalArea = parseFloat(aSlid.get());
    var cerealArea = parseFloat(cSlid.get());
    var oilsArea = parseFloat(oSlid.get());

    var minCerealArea = (animalArea * 0.13);
    var areaDeficit = (animalArea+cerealArea+greensArea+oilsArea+forestArea) - totalArea;
    var distributedDeficit = areaDeficit / totalAreaTypes;
    animalArea -=  distributedDeficit;
    cerealArea -= distributedDeficit;
    greensArea -=  distributedDeficit;
    oilsArea -=  distributedDeficit;
    aSlid.set(animalArea);
    cSlid.set(cerealArea);
    oSlid.set(oilsArea);
    gSlid.set(greensArea);
    displayAreas();
  }

  function createHectarium() {
    hectariumArray = new Array();
    var arrCounter = 0;
    var htmlStr = "";
    for (var y=0;y<hectariumImagesPerSide;y++) {
      for (var x=0;x<hectariumImagesPerSide;x++) {
        htmlStr+= "<div id=\"image_"+arrCounter+"\" class=\"image_forest\" ></div>";
        arrCounter++;
      }
      htmlStr+= "<br />\n";
    }
    $("#hectarium_plot").html(htmlStr);
    for (var i=0;i<arrCounter;i++) {
      hectariumArray[i] = new Object();
      hectariumArray[i].img = $("#image_"+i)[0];
      hectariumArray[i].areaType = 'forest';
    }
  }

  function displayAreas() {
    for (var i=0;i<controlSliders.length;i++) {
      $(controlSliders[i].noUiSlider.target).next().html(Math.round(controlSliders[i].noUiSlider.get())+" m<sup>2</sup>");
    }
    areaTypes[0].amount = parseFloat(aSlid.get());
    areaTypes[1].amount = parseFloat(cSlid.get());
    areaTypes[2].amount = parseFloat(gSlid.get());
    areaTypes[3].amount = parseFloat(oSlid.get());
    areaTypes[4].amount = parseFloat(fSlid.get());
    var blockCounter = 0;
    var divisor = Math.floor(totalArea / Math.pow(hectariumImagesPerSide,2));
    for (var a=0;a<areaTypes.length;a++) {
      var areaBlocks = Math.floor(areaTypes[a].amount / divisor);
      var maxBlocks = ((blockCounter+areaBlocks) > (hectariumArray.length-1)) ? blockCounter+areaBlocks : hectariumArray.length-1;
      for (var i=blockCounter;i<maxBlocks;i++) {
        if (hectariumArray[i].areaType != areaTypes[a].type) {
          hectariumArray[i].areaType = areaTypes[a].type;
          hectariumArray[i].img.className = "image_"+areaTypes[a].type;
        }
      }
      blockCounter += areaBlocks;
    }

  }

  function initializeSliders() {
    controlSliders = $(".hectarium_slider");
    for (var i=0;i<controlSliders.length;i++) {
      noUiSlider.create(controlSliders[i], {
        start: 0,
        snap: false,
        range: {
          'min': 0,
          'max': totalArea
        },
        pips: {
          mode: 'positions',
          values: [0,50,100],
          density: 2
        }
      });
      controlSliders[i].noUiSlider.on('slide', controlArray[controlSliders[i].noUiSlider.target.id]);
    }
    $("#control_forest")[0].noUiSlider.set(totalArea);
    $(".slider_val").html("0 m<sup>2</sup>");
    aSlid = $("#control_animals")[0].noUiSlider;
    cSlid = $("#control_cereals")[0].noUiSlider;
    gSlid = $("#control_greens")[0].noUiSlider;
    oSlid = $("#control_oils")[0].noUiSlider;
    fSlid = $("#control_forest")[0].noUiSlider;
  }

  return {
    initialize: initialize
  };

})();

$(function() {
  // Hectarium.initialize();
});