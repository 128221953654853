/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-06-09 16:06:48
* @Last Modified by:   Paul
* @Last Modified time: 2016-07-11 14:28:02
*/

'use strict';

class AnimatableDOM {

    constructor() {
        this.evtAnimationEnd = 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend';
        this.classAnimate = 'animated';
    }

    showDOM() {
        logger.log('AnimatableDOM - showDOM()');
        if (typeof this.dom === 'undefined') {
            return false;
        }
        if (typeof this.animations === 'undefined' || typeof this.animations.in === 'undefined') {
            return false;
        }

        this.animatable_dom.classList.add(this.animations.in);
    }

    addEventsListener(el, s, fn, capture) {
        if (typeof capture === 'undefined') {
            capture = false;
        }
        var evts = s.split(' ');
        for (var i = 0, iLen = evts.length; i < iLen; i++) {
            el.addEventListener(evts[i], fn, capture);
        }
    }

    createAnimatableDOM() {
        this.animatable_dom = document.createElement('div');
        this.animatable_dom.classList.add(this.classAnimate);
    }
}