/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-05-30 19:28:31
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-12 10:13:57
*/

(function ($) {
    'use strict';

    // Disabled keyup events to prevent Impress from sliding
    document.body.addEventListener("keyup", function( event ) {
        event.stopPropagation();
    }, false )
    
    window.logger = new Logger();
    window.eventDispatcher = new EventDispatcher();
    window.farmer = new Farmer({});
    window.hectariumController = new HectariumController();

}(jQuery));

function executeFunctionByName(functionName, context /*, args */) {
    var args = [].slice.call(arguments).splice(2);
    var namespaces = functionName.split(".");
    var func = namespaces.pop();
    for(var i = 0; i < namespaces.length; i++) {
        context = context[namespaces[i]];
    }
    console.log(context, functionName, args);
    return context[func].apply(context, args);
}