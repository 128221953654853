/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-08-10 11:57:03
* @Last Modified by:   Paul
* @Last Modified time: 2016-08-10 13:55:18
*/

'use strict';

class Resource {

    constructor(data) {
        this.name = data.name || '';
        this.amount = data.amount || 0; // current amount for this resource
        this.portion = data.portion || 1; // resource amount that represents one portion (g)
        this.surface_per_portion = data.surface_per_portion || 1; // surface required for one portion (m2)
        this.surface = data.surface || 0;
    }

    setAmount(amount) {
        this.amount = amount;
    }

    setSurface(surface) {
        this.surface = surface;
    }

    getPortionTotal() {
        // return this.amount * this.portion;
        if (this.surface === 0) {
            return 0;
        }
        return (this.surface / this.surface_per_portion) * this.portion;
    }

    getSurfaceTotal() {
        return this.amount * this.surface_per_portion;
    }

}