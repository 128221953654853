/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-06-05 15:11:17
* @Last Modified by:   Paul
* @Last Modified time: 2016-06-05 15:21:42
*/

'use strict';

class Point {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
}