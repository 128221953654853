/*
* @Author: Paul Gascou-Vaillancourt
* @Date:   2016-05-30 23:13:15
* @Last Modified by:   Paul
* @Last Modified time: 2016-05-30 23:13:24
*/

'use strict';

var DOMTokenList;

DOMTokenList.prototype.addMany = DOMTokenList.prototype.addMany ||function(classes) {
    var array = classes.split(' ');
    for (var i = 0, length = array.length; i < length; i++) {
      this.add(array[i]);
    }
};

DOMTokenList.prototype.removeMany = DOMTokenList.prototype.removeMany || function(classes) {
    var array = classes.split(' ');
    for (var i = 0, length = array.length; i < length; i++) {
      this.remove(array[i]);
    }
};